/* eslint-disable linebreak-style */
import {Box, Divider, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, useMediaQuery} from '@mui/material'
import {useDispatch, useSelector, batch} from 'react-redux'
import {useEffect, useState} from 'react'

import { LoopOutlined as LoopOutlinedIcon } from '@mui/icons-material'
import { format } from 'date-fns'
import { isMobileDown } from '../../../theme/deviceChecks'
import { RootStore } from '../../../redux/store'
import CardTitle from '../../../shared/UI/CardTitle'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import DatePicker from '../../../shared/UI/DatePicker'
import {
  BookingFloorPlan,
  BookingLocation,
  BookingZone,
} from '../../../services/booking/types'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import Button from '../../../shared/UI/Button'
import {
  setBookingFloorplanViewingDate,
  setBookingGridViewingDate,
  setBookingSliderPosition,
  setDeskBookingFloorplanManuallySelected,
  setDeskBookingFocussedZoneID,
  setDeskBookingLoading,
  setDeskBookingDashboardResults,
} from '../../../redux/reducers/deskBookingReducer'
import { bookingService } from '../../../services/booking/bookingService'
import { BaseResponse } from '../../../types/base-response'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import BookingErrorMessage from '../utils/BookingErrorMessage'
import { SelectOption } from '../../../services/dashboardService'
import { extractEmployees, getJson } from '../utils/utils'
import EmployeeAutoComplete from './EmployeeAutoComplete'
import Paragraph from '../../../shared/UI/Paragraph'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import DateRangePicker from '../../../shared/UI/DateRangePicker'
import BookingWeekdayPicker from './BookingWeekdayPicker'
import { BookingSearchType, BookingStatus } from './enums'
import {
  setBookingWizardBookingRangeState,
  setBookingWizardBookingsState,
  setBookingWizardFinalSelectionState,
  setBookingWizardSelectedDatesState,
  setBookingWizardSelectedFeatureState,
  setBookingWizardStepState,
  setBookingWizardWeekdaysSelectedState,
} from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingDateRange, ManagerBookingOptions } from '../consts'
import { BookingWizardSteps } from '../BlockBooking/enums'
import { checkIsOpenFloorplanArea, getDatesFromRange } from './bookingFloorplanLogic'
import { showComponents } from '../utils'
import { BookinResetSearch } from './components'
import { BookingPeriods } from '../Popover/enums'
import { BookingSliderPosition } from '../../../utils/constants'
import {
  setDeskBookingSearchParams,
  setDeskBookingSearchResults,
} from '../../../redux/reducers/deskBookingSearchReducer'

import {
  getDashboardBookings,
  onBookingGridViewDataLoaded,
  handleSearch,
  handleServerError,
  handleResetBookingWizardFloorPlan,
} from '../bookingLogic'


const bookingForOptions: SelectOption[] = [
  { value: ManagerBookingOptions.MYSELF, displayValue: 'Myself' },
  { value: ManagerBookingOptions.EMPLOYEE, displayValue: 'Employee' },
  { value: ManagerBookingOptions.VISITOR, displayValue: 'Visitor' },
]

interface FormFieldsSelectedCheck {
  isLocationSelected: boolean
  isFloorplanSelected: boolean
  isZoneSelected: boolean
}


type ModalProps = {
  dashboardButtonOnClick?: (click:boolean) => void
}

export default function BookingSearch({dashboardButtonOnClick}: ModalProps) {

  const isMobile = useMediaQuery(isMobileDown())

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedBookingFor, setSelectedBookingFor] = useState<SelectOption>({ value: 1, displayValue: 'Myself' })
  const [selectedLocation, setSelectedLocation] = useState<BookingLocation>()
  const [selectedFloorplan, setSelectedFloorplan] = useState<BookingFloorPlan>()
  const [selectedZone, setSelectedZone] = useState<BookingZone>()
  const [employees, setEmployees] = useState<SelectOption[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<SelectOption>()
  const [blockDateRange, setBlockDateRange] = useState<
    [Date, Date] | [null, null] | [Date, null] | [null, Date]
  >([null, null])
  const [selectedFromTo, setSelectedFromTo] = useState<{ from: string; to: string }>({
    from: '00:00:01',
    to: '23:59:59',
  })
  const [bookingLength, setBookingLength] = useState('1')
  const [blockBookMonthsLimit, setBlockBookMonthsLimit] = useState<number>(1)

  const [isOpenFloorplanArea, setIsOpenFloorplanArea] = useState<boolean>(false)

  const dispatch = useDispatch()

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }

  const {
    locations,
    floorplans,
    zones,
    maxBookingDate,
    gridViewingDate,
    featuresForFloorPlan,
    dashboardResults,
    showGridView,
    showFloorplan,
    bookingIsLoading,
    showMeBookingID,
    focussedZoneID,
    mostUsedDesk,
    bookingSliderPosition,
  } = useSelector((state: RootStore) => state.deskBooking)
  const { searchResults, searchParams, searchRecentParams } = useSelector(
    (state: RootStore) => state.deskBookingSearch
  )

  const { currentStep: wizardCurrentStep, weekdaysSelected: wizardWeekdaysSelected } = useSelector(
    (state: RootStore) => state.deskBookingWizard
  )

  const { employeeDetails, directReports } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const [searchType, setSearchType] = useState<BookingSearchType>(BookingSearchType.SINGLE)

  const zonesForDropdown = () => {
    if (!zones || !selectedFloorplan) {
      return []
    }
    return zones
      .filter(f => f.floorPlanId === selectedFloorplan?.id)
      .filter(m => {
        const json = getJson(m.additionalInfo)
        return !json?.ui || json?.ui?.showInDropdown === true
      })
  }

  const isManager = userPermissions.includes('BookingManager')

  const setDefaultEmployeeFloorAndZoneFromMostUsedDesk = () => {
    setSelectedDate(new Date())

    if (mostUsedDesk) {
      setSelectedFloorplan(floorplans.find(f => f.id === Number(mostUsedDesk.floorPlanId)))
      setSelectedZone(zones.find(f => f.id === Number(mostUsedDesk.zoneId)))
    }
  }

  const handleResetNoDate = () => batch(() => {
    
    setBookingLength('1')
    setSelectedBookingFor({ value: ManagerBookingOptions.MYSELF, displayValue: 'Myself' })
    setSelectedLocation(locations.find(f => f.id === employeeDetails.location.id)) // setSelectedLocation(undefined)
    setSelectedFloorplan(undefined)
    setSelectedZone(undefined)
    setEmployees([])
    setSelectedEmployee(undefined)
    setBlockDateRange([null, null])
    dispatch(setBookingFloorplanViewingDate(new Date()))
    dispatch(setBookingGridViewingDate(new Date()))
    dispatch(setBookingWizardBookingRangeState([new Date(), new Date()]))
    dispatch(setBookingWizardSelectedDatesState([]))
    dispatch(setDeskBookingSearchResults([]))
    dispatch(setBookingWizardSelectedFeatureState(undefined))
    dispatch(setBookingWizardBookingsState([]))
    dispatch(setBookingWizardFinalSelectionState([]))

    if (isManager && Boolean(employees?.length)) {
      setSelectedEmployee({ value: 0, displayValue: '' })
    }

    // this was commented out to fix search forn reset issues
    // dispatch(setDeskBookingSearchParams(undefined))

    dispatch(setBookingSliderPosition({
      slidePosition: BookingSliderPosition.BOOKINGS,
      buttonClick: false
    }))

    if (isMobile) return

    showComponents(dispatch, {
      search: true,
      gridview: true,
      navbar: true,
      filter: false,
      blockBookingSummary: false,
      blockBookingWizard: false 
    })
  })

  const [formFieldsSelectedCheck, setFormFieldsSelectedCheck] = useState<FormFieldsSelectedCheck>({
    isLocationSelected: false,
    isFloorplanSelected: false,
    isZoneSelected: false,
  })

  useEffect(() => {
    const isLocationSelected = selectedLocation !== undefined && selectedLocation !== null
    const isFloorplanSelected = selectedFloorplan !== undefined && selectedFloorplan !== null
    const isZoneSelected = selectedZone !== undefined && selectedZone !== null

    setFormFieldsSelectedCheck({
      isLocationSelected,
      isFloorplanSelected,
      isZoneSelected,
    })
  }, [selectedLocation, selectedFloorplan, selectedZone])

  const getLatestDashboardBookings = (reset = true) =>
    getDashboardBookings(
      employeeDetails.employeeId,
      !reset ? gridViewingDate || new Date() : new Date(),
      dispatch,
      onBookingGridViewDataLoaded,
      false // true
    )

  const resetBookings = () => {

    const bookingsLoadingOrExist = bookingIsLoading // || dashboardResults?.results[2]

    if (bookingsLoadingOrExist) {
      return
    }

    batch(() => {
      dispatch(setDeskBookingLoading(true))
      dispatch(setBookingGridViewingDate(new Date()))
      dispatch(setDeskBookingDashboardResults([]))
      dispatch(setDeskBookingSearchResults([]))
      // dispatch(setDeskBookingSearchParams(undefined))
    })

    getLatestDashboardBookings(true)

  }

  const resetEmployeeDashboard = () => {
    const { isFloorplanSelected, isLocationSelected, isZoneSelected } = formFieldsSelectedCheck

    if (!isFloorplanSelected || !isLocationSelected || !isZoneSelected) {
      return
    }

    const bookingsExist = dashboardResults?.results[0] || dashboardResults?.results[1]

    if (bookingsExist) {
      resetBookings()
    }
  }

  const handleStandardResetBookingWizardFloorPlan = () => {
    if (showGridView) {
      return
    }

    resetEmployeeDashboard()
    getLatestDashboardBookings()

    handleResetBookingWizardFloorPlan(dispatch)

    batch(() => {
      dispatch(setBookingFloorplanViewingDate(new Date()))
      dispatch(setBookingGridViewingDate(new Date()))

      showComponents(dispatch, {
        search: true,
        gridview: true,
        navbar: true,
        filter: false,
        blockBookingSummary: false,
        blockBookingWizard: false 
      })
    })

  }

  const handleResetWithDate = () => {
    setSelectedDate(null)

    handleResetNoDate()

    // setDefaultEmployeeFloorAndZoneFromMostUsedDesk()
  }

  const handleResetButtonWithDate = () => {
    resetBookings()

    handleResetWithDate()
  }

  useEffect(() => {

    if (isMobile && !showFloorplan) handleResetWithDate()

  }, [isMobile && !showFloorplan])

  useEffect(() => {
    if (showMeBookingID !== undefined) {
      return
    }

    handleResetWithDate()
  }, [])

  useEffect(() => {
    setSelectedLocation(locations.find(f => f.id === employeeDetails.location.id))
  }, [locations, employeeDetails.location.id])


  useEffect(() => {
    if (selectedBookingFor.value === 1) {
      // Only booking for one's self, so exit.
      return
    }

    setEmployees(extractEmployees(directReports))
  }, [directReports, selectedBookingFor])

  useEffect(() => {
    if (!searchRecentParams || !searchResults) {
      return
    }

    bookingService
      .queryRecent(searchRecentParams)
      .then(result => {
        dispatch(
          setDeskBookingSearchResults([
            ...searchResults,
            ...result.bookings,
          ].filter(f => f.statusId === BookingStatus.ACCEPTED))
        )
      })
      .catch(err => {
        const response: BaseResponse = handleServerError(err.response.data)
        response.errors.forEach(error => {
          handleSearchError(error.name)
        })
        dispatch(setDeskBookingLoading(false))
      })
  }, [searchRecentParams])

  useEffect(() => {
    if (!searchParams || !zones || !searchResults || !employeeDetails) {
      return
    }

    const populateSearchBoxes = (floorplan: BookingFloorPlan) =>
      batch(() => {
        if (!floorplan) {
          return
        }

        setSelectedDate(searchParams.date || new Date())
        setSelectedLocation(locations.find(f => f.id === employeeDetails.location.id))
        if (searchParams?.locationId) {
          setSelectedLocation(locations.find(f => f.id === searchParams?.locationId))
        }

        setSelectedFloorplan(floorplan)
        if (searchParams.zoneId) {
          setSelectedZone(zones.find(f => f.id === searchParams.zoneId))
        }
        if (!searchParams.zoneId && focussedZoneID) {
          setSelectedZone(zones.find(f => f.id === focussedZoneID))
        }
      })

    const floorplan = floorplans.find(f => f.id === searchParams?.floorplanId)
    if (!floorplan) {
      return
    }

    populateSearchBoxes(floorplan)
  }, [dispatch, searchParams, zones && floorplans && locations, focussedZoneID])

  /*
  useEffect(() => {

    setDefaultEmployeeFloorAndZoneFromMostUsedDesk()

  }, [mostUsedDesk, zones && floorplans, !searchParams, !searchResults])
 */

  useEffect(() => {
    if (searchParams && searchResults) {
      return
    }

    setTimeout(() => {
      if (showGridView && !bookingSliderPosition.buttonClick) {
        return
      }

      handleResetButtonWithDate()
    }, 13)
  }, [!searchParams && showGridView && bookingSliderPosition])

  const employeeLocationId = locations.find(l => l.id === employeeDetails.location.id)?.id

  useEffect(() => {

    const thisSelectedLocationId = selectedLocation?.id || employeeLocationId || 0
    const thisSelectedFloorplanId = selectedFloorplan?.id || 0

    setIsOpenFloorplanArea(
      checkIsOpenFloorplanArea(thisSelectedLocationId, thisSelectedFloorplanId)
    )

  }, [selectedLocation && selectedFloorplan])

  const disableFind = () => {
    const hasNotSelectedEmployee = !selectedEmployee || !selectedEmployee?.displayValue.length
    const isManagerBookingForOther = selectedBookingFor.value !== ManagerBookingOptions.MYSELF
    const isManagerBookingForVisitor = selectedBookingFor.value === ManagerBookingOptions.VISITOR
    if (
      hasNotSelectedEmployee && isManagerBookingForOther &&
        !isManagerBookingForVisitor
    ) {
      return true
    }

    if (!selectedDate && !blockDateRange[BookingDateRange.FROM]) {
      return true
    }
    if (
      wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE &&
        wizardWeekdaysSelected.length === 0
    ) {
      return true
    }

    if (!showGridView && bookingIsLoading) {
      return true
    }

    const { isFloorplanSelected, isLocationSelected, isZoneSelected } = formFieldsSelectedCheck
    const isOpenFloorplanOrZone = isOpenFloorplanArea ? true : isZoneSelected

    return !(isLocationSelected && isFloorplanSelected && isOpenFloorplanOrZone)
  }

  const [showSearchLoading, setShowSearchLoading] = useState(false)

  const searchDataNotAvailable = !locations?.length || !floorplans?.length || !zones?.length

  useEffect(() => {

    if (!searchDataNotAvailable) {

      return
    }

    const showLoadingTimeId = setTimeout(() => {

      setShowSearchLoading(true)
    }, 3000)

    return () => {
      clearTimeout(showLoadingTimeId)
    }
  }, [])

  if (searchDataNotAvailable) {

    if (!showSearchLoading) {
      return null
    }

    return (
      <Box display="flex" height="470px" flexDirection="column" justifyContent="top">
        <Box display="flex" alignItems="center" flexDirection="column">
          <LoadingIndicator show alignItems="center" margin="20px" />
          <Paragraph size="16px" weight="bold" color="#2c2965">
            Loading search data...
          </Paragraph>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs display="flex" alignItems="center">
          <Box flexGrow={1} p={1} paddingBottom={2} paddingTop={isMobile ? 2 : 1}>
            <CardTitle title="Find a desk" />
          </Box>
        </Grid>
        {isMobile && dashboardButtonOnClick &&
          <Grid item width="100px" display="flex" alignItems="center" pr={5} sx={{paddingTop: '10px !important'}}>
            <Box flexGrow={1}>
              <ButtonSmall
                sx={{padding: '2px !important', margin: '0px', marginLeft: '-8px', marginBottom: '14px', minWidth: '100px', fontWeight: 600, fontSize: '11px'}}
                label="Dashboard"
                onClick={() => dashboardButtonOnClick(false)}
              />
            </Box>
          </Grid>
        }
      </Grid>
      <Grid container spacing={2} minHeight="350px" height={isMobile ? '100%' : '540px'} alignContent="flex-start">
        <Grid item xs={12}>

          {isMobile &&
            <Box mt={2} mb={1} marginTop="10px">
              <DatePicker
                maxDate={maxBookingDate}
                value={selectedDate}
                label="* When?"
                disablePast
                onChange={date => {
                  if (!date || Object.prototype.toString.call(date) !== '[object Date]') {
                    return
                  }
                  handleStandardResetBookingWizardFloorPlan()
                  setSelectedDate(date)
                  setBlockDateRange([date, null])
                  setSearchType(_=> BookingSearchType.SINGLE)
                  dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
                }}
              />
            </Box>
          }

          {!isMobile &&
            <Box mt={2} marginTop="25px">
              <DateRangePicker
                calendars={1}
                spacing={2}
                maxDate={maxBookingDate}
                value={blockDateRange}
                arrange="Horizontal"
                disablePast
                closeOnSelect
                disableOnOrAfterMonths={blockBookMonthsLimit}
                onChange={dateRange => batch(() => {
                  const [dateFrom, dateTo] = dateRange

                  handleStandardResetBookingWizardFloorPlan()
                  setBlockDateRange([dateFrom, dateTo])
                  if (Number(dateFrom) === Number(dateTo)) {
                    setSelectedDate(dateFrom)
                    setSearchType(_=> BookingSearchType.SINGLE)
                    dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
                  } else {
                    dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_1_SEARCH))
                    dispatch(setBookingWizardWeekdaysSelectedState([]))
                    setSearchType(_=> BookingSearchType.BLOCK)
                  }

                  if (!showGridView) {

                    batch(() => {
                      dispatch(setDeskBookingLoading(true))
                      dispatch(setDeskBookingDashboardResults([]))
                    })
                    getLatestDashboardBookings(false)
                  }

                  showComponents(dispatch, { gridview: true, navbar: true, search: true, filter: false })
                  // dispatch(setDeskBookingSearchResults([]))
                })}
              />
              {Number(blockDateRange[0]) !== Number(blockDateRange[1]) && blockDateRange[1] !== null && (
                <>
                  <Paragraph weight="bold" style={{ margin: '16px 0' }}>
                    Select Days
                  </Paragraph>
                  <BookingWeekdayPicker
                    blockDates={blockDateRange}
                  />
                  <Divider />
                </>
              )}
            </Box>
          }
        </Grid>
        {isManager && (
          <Grid item xs={12}>
            <DropdownMenu
              label="Booking For"
              data={bookingForOptions}
              textField="displayValue"
              valueField="value"
              id="bookingFor"
              value={selectedBookingFor.value}
              onChange={e => {
                const selectedValue = Number(e.target.value)
                if (selectedValue === ManagerBookingOptions.MYSELF || selectedValue === ManagerBookingOptions.VISITOR) {
                  setSelectedEmployee({ value: 0, displayValue: '' })
                }
                setSelectedBookingFor(bookingForOptions.find(f => f.value === selectedValue)!)
              }}
            />
          </Grid>
        )}
        {isManager && selectedBookingFor.value !== ManagerBookingOptions.MYSELF && selectedBookingFor.value !== ManagerBookingOptions.VISITOR && Boolean(employees?.length) && (
          <Grid item xs={12}>
            <EmployeeAutoComplete
              id="employees"
              label="All Employees"
              data={employees}
              textField="displayValue"
              onInputRef={ref => {
                if (selectedEmployee || selectedBookingFor.value === ManagerBookingOptions.MYSELF || selectedBookingFor.value === ManagerBookingOptions.VISITOR) {
                  return
                }
                ref?.focus()
              }}
              onChange={(_e, employee) => {
                if (!employee) {
                  setSelectedEmployee(undefined)
                  return
                }
                if (typeof employee === 'string') {
                  setSelectedEmployee({ value: -1, displayValue: employee })
                  return
                }
                setSelectedEmployee({
                  value: employee.value,
                  displayValue: employee.displayValue.replace('Add', '').replace(/"/g, '').trim(),
                })
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DropdownMenu
            label="* Location"
            data={locations}
            textField="name"
            valueField="id"
            id="location"
            value={selectedLocation?.id || employeeLocationId || ''}
            onChange={e => {
              if (selectedLocation?.id === Number(e.target.value)) {
                return
              }

              handleStandardResetBookingWizardFloorPlan()

              setSelectedLocation(
                locations.find(f => f.id === (Number(e.target.value) || employeeLocationId))
              )

              setSelectedFloorplan(undefined)
              setSelectedZone(undefined)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownMenu
            label="* Floor"
            data={floorplans.filter(
              f => f.locationId === (selectedLocation?.id || employeeLocationId)
            )}
            textField="name"
            valueField="id"
            id="floorplan"
            value={selectedFloorplan?.id || ''}
            onChange={e => {
              const thisSelectedFloorPlanId = Number(e.target.value)
              if (selectedFloorplan?.id === thisSelectedFloorPlanId) {
                return
              }

              handleStandardResetBookingWizardFloorPlan()

              setSelectedFloorplan(floorplans.find(f => f.id === thisSelectedFloorPlanId))

              const thisSelectedLocationId = selectedLocation?.id || employeeLocationId || 0

              setIsOpenFloorplanArea(
                checkIsOpenFloorplanArea(thisSelectedLocationId, thisSelectedFloorPlanId)
              )

              setSelectedZone(undefined)

              /*
              const setDefaultEmployeeZone = zones.find(
                f =>
                  f.belongsTo === employeeDetails.departmentId &&
                  f.floorPlanId === thisSelectedFloorPlanId
              ) // || defaultZoneIdIfNoneSelected(zones)

              setSelectedZone(undefined)

              if (setDefaultEmployeeZone) {
                setSelectedZone(setDefaultEmployeeZone)
              }
              */
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <DropdownMenu
            label={`${isOpenFloorplanArea ? '' : '* '}Zone`}
            data={isOpenFloorplanArea ? [{id: 0, name: "Any"}] : zonesForDropdown()}
            textField="name"
            valueField="id"
            disabled={!selectedFloorplan || isOpenFloorplanArea}
            id="zone"
            value={isOpenFloorplanArea ? 0 : selectedZone?.id || ''}
            onChange={e => {
              if (selectedZone?.id === Number(e.target.value)) {
                return
              }

              handleStandardResetBookingWizardFloorPlan()

              const zone = zones.find(f => f.id === Number(e.target.value))
              setSelectedZone(zone)
              // dispatch(setDeskBookingFocussedZoneID(zone?.id || 0))
            }}
          />
        </Grid>
        {wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE && (
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="grouped-select">Booking Length</InputLabel>
              <Select
                id="grouped-select"
                label="Booking Length"
                value={bookingLength}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {},
                      '& .MuiListSubheader-root': {
                        fontWeight: 'bold',
                      },
                      '& .MuiButtonBase-root:not(:first-child)': {
                        paddingLeft: '25px',
                      },
                    },
                  },
                }}
                disabled={!isOpenFloorplanArea && !selectedZone}
                onChange={e => {
                  handleStandardResetBookingWizardFloorPlan()

                  // TODO: refactor out into shared helper func to fix bugs around Booking Length Search Form
                  //        not populating correctly for half days from existing bookings
                  const fromTo = { from: '', to: '' }
                  switch (String(e.target.value)) {
                    case BookingPeriods.FULL_DAY:
                      fromTo.from = '00:00:00'
                      fromTo.to = '23:59:59'
                      setBookingLength(BookingPeriods.FULL_DAY)
                      break
                    case BookingPeriods.HALF_DAY_FIRST_HALF:
                      fromTo.from = '00:00:00'
                      fromTo.to = '11:59:59'
                      setBookingLength(BookingPeriods.HALF_DAY_FIRST_HALF)
                      break
                    case BookingPeriods.HALF_DAY_SECOND_HALF:
                      fromTo.from = '12:00:00'
                      fromTo.to = '23:59:59'
                      setBookingLength(BookingPeriods.HALF_DAY_SECOND_HALF)
                      break
                    default:
                      break
                  }

                  setSelectedFromTo(fromTo)
                }}
              >
                <MenuItem value={1}>Full Day</MenuItem>
                <ListSubheader>Half Day:</ListSubheader>
                <MenuItem value={2}>First Half</MenuItem>
                <MenuItem value={3}>Second Half</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: isMobile ? '50px' : '0'
          }}
        >
          <Button
            label="Find"
            disabled={disableFind()}
            fullWidth
            style={{ margin: 0, marginTop: '0px' }}
            onClick={e => batch(async () => {
              const selectedData = {
                selectedFromTo,
                searchType,
              }

              const validBlockBookingDates =
                blockDateRange[BookingDateRange.FROM] !== null &&
                blockDateRange[BookingDateRange.TO] !== null

              if (
                selectedLocation &&
                selectedFloorplan &&
                (selectedDate || validBlockBookingDates)
              ) {
                dispatch(
                  setDeskBookingSearchParams({
                    locationId: selectedLocation?.id,
                    floorplanId: selectedFloorplan?.id,
                    employeeID: selectedEmployee?.value || employeeDetails.employeeId,
                    employeeName:
                      selectedEmployee?.displayValue ||
                      `${employeeDetails.employeeForename} ${employeeDetails.employeeSurname}`,
                    date:
                      selectedDate ||
                      (blockDateRange[BookingDateRange.FROM] &&
                        new Date(format(blockDateRange[BookingDateRange.FROM], 'yyyy-MM-dd'))) ||
                      new Date(),
                    ...(blockDateRange[BookingDateRange.TO] && {
                      dateTo: format(blockDateRange[BookingDateRange.TO], 'yyyy-MM-dd'),
                    }),
                    from: selectedFromTo?.from,
                    to: selectedFromTo?.to,
                    autoSearch: false,
                    zoneId: selectedZone?.id,
                    bookingFor: selectedBookingFor?.value
                  })
                )
              }

              const isValidEmployeeId = selectedEmployee && selectedEmployee?.value > 0
              const isDifferentEmployeeIdToCurrentUser =
                employeeDetails.employeeId !== selectedEmployee?.value

              const thisSearchEmployeeDetails = {
                ...employeeDetails,
                employeeId: Number(
                  isValidEmployeeId && isDifferentEmployeeIdToCurrentUser
                    ? selectedEmployee?.value
                    : employeeDetails.employeeId
                ),
              }

              dispatch(
                setBookingSliderPosition({
                  slidePosition: BookingSliderPosition.FLOOR_PLAN,
                  buttonClick: false,
                })
              )
              dispatch(setDeskBookingFloorplanManuallySelected(false))
              dispatch(setDeskBookingFocussedZoneID(selectedZone?.id || 0))

              if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {
                if (!selectedDate) {
                  return
                }
                dispatch(setBookingFloorplanViewingDate(selectedDate))
                showComponents(dispatch, { floorplan: true, navbar: true, search: true })
                if (!selectedLocation || !selectedFloorplan) {
                  return
                }

                const singleQuery = true
                await handleSearch(
                  dispatch,
                  handleSearchError,
                  singleQuery,
                  zones,
                  featuresForFloorPlan,
                  thisSearchEmployeeDetails,
                  userInfo,
                  selectedData,
                  selectedLocation.id,
                  selectedFloorplan.id,
                  null,
                  selectedDate
                )

              } else {
                if (
                  !selectedLocation ||
                  !selectedFloorplan ||
                  !blockDateRange[BookingDateRange.FROM] ||
                  !blockDateRange[BookingDateRange.TO]
                ) {
                  return
                }

                showComponents(dispatch, {
                  floorplan: true,
                  search: true,
                  blockBookingWizard: true,
                })

                dispatch(setBookingFloorplanViewingDate(blockDateRange[BookingDateRange.FROM]))
                dispatch(setBookingWizardSelectedDatesState(getDatesFromRange(blockDateRange)))
                dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_2_SELECT_DESK))
                dispatch(setBookingWizardBookingRangeState(blockDateRange))

                const singleQuery = false
                await handleSearch(
                  dispatch,
                  handleSearchError,
                  singleQuery,
                  zones,
                  featuresForFloorPlan,
                  employeeDetails,
                  userInfo,
                  selectedData,
                  selectedLocation.id,
                  selectedFloorplan.id,
                  null,
                  blockDateRange[BookingDateRange.FROM],
                  blockDateRange[BookingDateRange.TO]
                )

              }
            })}
          />

          <BookinResetSearch id="reset" onClick={() => handleResetButtonWithDate()}>
            <LoopOutlinedIcon style={{ width: '18px', marginRight: '5px' }} />
            Reset
          </BookinResetSearch>
        </Grid>
      </Grid>
    </>
  )
}
