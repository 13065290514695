import { Box, Grid, Stack } from '@mui/material'
import { useState, useEffect, useRef, useMemo } from 'react'
import {
  CalendarToday as CalendarTodayIcon,
  Map as MapIcon,
} from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import locale from 'date-fns/locale/en-GB'
import { format, startOfMonth, subMinutes } from 'date-fns'
import { useDispatch, useSelector, batch } from 'react-redux'
import Paragraph from '../../../shared/UI/Paragraph'
import SlideButton from './SlideButton'
import { RootStore } from '../../../redux/store'
import {
  setBookingFloorplanViewingDate,
  setBookingGridViewingDate,
  setDeskBookingFloorplanManuallySelected,
  setBookingSliderPosition,
  setDeskBookingFocussedZoneID,
  setDeskBookingDashboardResults,
  setDeskBookingLoading,
  setDeskBookingShowMeBookingID,
} from '../../../redux/reducers/deskBookingReducer'
import { BookingNavBarProps } from './types'
import CardTitleSub from '../../../shared/UI/CardTitleSub'
import { dateToNumber } from '../utils/utils'
import { setBookingWizardStepState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'
import {
  defaultFloorplanIdIfNoneSelected,
  defaultZoneIdIfNoneSelected,
  showComponents,
} from '../utils'
import { formatDatTimeWithTimeZoneStr, formatDateWithTimeZone } from '../../../utils/date-utils'
import { BookingSliderIndex, BookingSearchType } from './enums'
import {
  setDeskBookingSearchParams,
  setBookingSearchRecentParams,
  setDeskBookingSearchResults,
} from '../../../redux/reducers/deskBookingSearchReducer'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import BookingErrorMessage from '../utils/BookingErrorMessage'
import {BookingZone} from '../../../services/booking/types'
import {
  handleSearch,
} from '../bookingLogic'
import theme from '../../../theme/theme'

export function NavBar({ title, subTitle, onNav }: BookingNavBarProps) {

  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => () => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    },
    []
  )

  const dispatch = useDispatch()

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }


  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const [selectedZone] = useState<BookingZone>()

  const [selectedFromTo, setSelectedFromTo] = useState<{ from: string; to: string }>({
    from: '00:00:01',
    to: '23:59:59',
  })

  const {
    floorplanViewingDate,
    showGridView,
    showFloorplan,
    floorplans,
    featuresForFloorPlan,
    maxBookingDate,
    zones,
  } = useSelector((state: RootStore) => state.deskBooking)

  const { searchParams } = useSelector((state: RootStore) => state.deskBookingSearch)

  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const [searchType, setSearchType] = useState<BookingSearchType>(BookingSearchType.SINGLE)

  const employeeFloorplans = useMemo(
    () =>
      floorplans.filter(f => f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY],
    [employeeDetails.location.id, floorplans]
  )

  const employeeZones = useMemo(
    () =>
      zones.filter(
        z =>
          employeeFloorplans.some(s => s.id === z.floorPlanId) &&
          z.belongsTo === employeeDetails.departmentId
      ),
    [employeeDetails.departmentId, employeeFloorplans, zones]
  )

  const selectedData = {
    selectedFromTo,
    searchType,
  }

  return (
    <>
      <Grid container id="navbar" display="flex" justifyContent="flex-end">
        <Grid item xs={4} md={4} width="80px" display="flex" alignItems="left">
          <Box flexGrow={1}>
            <CardTitleSub title={title} subTitle={subTitle} />
          </Box>
        </Grid>

        <Grid item xs={4} md={3} display="flex" alignItems="center" pr={1}>
          <Stack direction="row" justifyContent="flex-end" flexGrow={3} alignItems="center" gap={1}>
            {showFloorplan && (
              <>
                <Paragraph weight="medium" color={theme.palette.primary.main} padding='3px 0px 0px'>
                  {dateToNumber(floorplanViewingDate) === dateToNumber(new Date())
                    ? 'Today'
                    : format(floorplanViewingDate, 'EEE dd/MM/yyyy')}
                </Paragraph>

                <Box mt={2} mb={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <DatePicker
                      OpenPickerButtonProps={{
                        sx: {
                          color: theme.palette.primary.main
                        }
                      }}
                      maxDate={maxBookingDate}
                      value={floorplanViewingDate}
                      disablePast
                      onChange={newDate => {
                        if (!newDate || Object.prototype.toString.call(newDate) !== '[object Date]') {
                          return
                        }

                        if (newDate !== floorplanViewingDate) {

                          dispatch(setBookingFloorplanViewingDate(newDate))

                          if (!showFloorplan || !searchParams) {

                            return
                          }

                          dispatch(setDeskBookingDashboardResults([]))
                          dispatch(setDeskBookingLoading(true))

                          setBookingSearchRecentParams({
                            fromDateTime: formatDatTimeWithTimeZoneStr(subMinutes(newDate, 1)),
                            date: format(newDate, 'yyyy-MM-dd'),
                            floorPlanID: searchParams?.floorplanId,
                            from: searchParams?.from,
                            to: searchParams?.to,
                          })

                          if (searchParams.from && searchParams.to) {

                            const {from, to} = searchParams

                            setSelectedFromTo({
                              from,
                              to
                            })
                          }

                          dispatch(
                            setDeskBookingSearchParams({
                              ...searchParams,
                              date: newDate,
                              autoSearch: true,
                            })
                          )
                          dispatch(setDeskBookingFocussedZoneID(searchParams?.zoneId || 0))

                          handleSearch(
                            dispatch,
                            handleSearchError,
                            !showGridView,
                            zones,
                            featuresForFloorPlan,
                            employeeDetails,
                            userInfo,
                            selectedData,
                            searchParams?.locationId, 
                            searchParams?.floorplanId,
                            null,
                            newDate
                          )

                        }
                      }}
                      renderInput={({ inputRef, InputProps }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-3px' }} ref={inputRef}>
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4} md={5} display="flex" justifyContent="flex-end">
          <SlideButton
            setIdx={showGridView ? BookingSliderIndex.GRID_VIEW : BookingSliderIndex.FLOORPLAN_VIEW}
            width="300px"
            buttons={[
              {
                label: 'Bookings',
                icon: <CalendarTodayIcon fontSize="inherit" sx={{ marginRight: '8px' }} />,
              },
              {
                label: 'Floor plan',
                icon: <MapIcon fontSize="inherit" sx={{ marginRight: '8px' }} />,
              },
            ]}
            onSelect={idx => batch(async () => {
              dispatch(setBookingFloorplanViewingDate(new Date()))
              if (idx === BookingSliderIndex.GRID_VIEW) {
                dispatch(setBookingGridViewingDate(startOfMonth(new Date())))
                dispatch(setDeskBookingFloorplanManuallySelected(false))

                dispatch(setDeskBookingShowMeBookingID(undefined))

                showComponents(dispatch, { gridview: true, navbar: true, search: true })
                dispatch(setBookingSliderPosition({
                  slidePosition: idx,
                  buttonClick: true
                }))

                dispatch(setDeskBookingSearchResults([]))
                dispatch(setDeskBookingSearchParams(undefined))

              }
              if (idx === BookingSliderIndex.FLOORPLAN_VIEW) {
                dispatch(setBookingGridViewingDate(new Date()))
                showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })
                dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
                dispatch(setDeskBookingFloorplanManuallySelected(true))
                dispatch(setBookingSliderPosition({
                  slidePosition: idx,
                  buttonClick: true
                }))

                if (!employeeZones.length && !employeeFloorplans.length) {
                  return
                }

                const zoneId: number = (employeeZones[0] || defaultZoneIdIfNoneSelected(employeeZones)).id

                dispatch(setDeskBookingFocussedZoneID(defaultZoneIdIfNoneSelected(employeeZones)))

                dispatch(
                  setDeskBookingSearchParams({
                    locationId: employeeDetails.location.id,
                    floorplanId: defaultFloorplanIdIfNoneSelected(
                      employeeZones,
                      employeeFloorplans
                    ),
                    zoneId,
                    date: new Date(),
                    from: searchParams?.from || '00:00:00',
                    to:  searchParams?.to ||'23:59:59',
                    autoSearch: true,
                  })
                )

                const singleQuery = true
                await handleSearch(
                  dispatch,
                  handleSearchError,
                  singleQuery,
                  zones,
                  featuresForFloorPlan,
                  employeeDetails,
                  userInfo,
                  selectedData,
                  employeeDetails.location.id, 
                  defaultFloorplanIdIfNoneSelected(
                    employeeZones,
                    employeeFloorplans
                  ),
                  null,
                  new Date()
                )

              }
            })}
          />
        </Grid>
      </Grid>
    </>
  )
}
